<template>
  <div class="view-page-title d-flex align-items-center" v-if="!onlyAction">
    <span class="title" v-html="title"></span>
    <span class="ml-auto">
      <span class="view-mode pointer mr-3" :class="{'active': viewList}" @click="setViewMode(true)" v-html="require('~/assets/mlearn/icons/view-list.svg?raw')"></span>
      <span class="view-mode pointer" :class="{'active': !viewList}" @click="setViewMode(false)" v-html="require('~/assets/mlearn/icons/view-detail.svg?raw')"></span>
    </span>
  </div>
  <span v-else>
    <span class="view-mode pointer mr-3" :class="{'active': viewList}" @click="setViewMode(true)" v-html="require('~/assets/mlearn/icons/view-list.svg?raw')"></span>
    <span class="view-mode pointer" :class="{'active': !viewList}" @click="setViewMode(false)" v-html="require('~/assets/mlearn/icons/view-detail.svg?raw')"></span>
  </span>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  props: {
    onlyAction: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: 'Tài liệu',
      required: false
    }
  },
  data () {
    return {
      viewList: true
    }
  },
  mounted () {
    this.viewList = parseInt(Cookies.get('viewList') || 1) === 1
    this.setViewMode(this.viewList)
  },
  methods: {
    setViewMode (mode = false) {
      if (mode) {
        Cookies.set('viewList', 1)
      } else {
        Cookies.set('viewList', 0)
      }
      this.viewList = mode
      this.$emit('mode', mode)
    }
  }
}
</script>

<style lang="scss" scoped>
  .view-page-title{
    font-weight: bold;
    font-size: 24px;
    @media (max-width:1366px) {
      font-size: 16px;
    }
    @media (max-width:576px) {
      font-size: 16px;
    }
    .title{
      @media (max-width:576px) {
        display: none;
      }
    }
  }
</style>
