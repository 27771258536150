<template>
  <b-modal id="modal-verify-otp" title="" centered hide-footer @hidden="hideFormEnterOtp" @shown="showFormEnterOtp" :no-close-on-backdrop="true">
    <div class="content-otp">
      <h4>Xác thực số điện thoại</h4>
      <div class="verify-text">Nhập mã xác thực vừa gửi đến số điện thoại của bạn</div>
      <div class="verify-label">Mã xác thực</div>
      <b-form-input class="verify-code" v-model="otpCode" required ref="input-otp" placeholder="Nhập mã xác thực"></b-form-input>
      <div v-if="textOtpError" class="text-danger">{{ textOtpError }}</div>
      <div class="verify-bottom d-flex pt-3">
        <p class="verify-desc">Không nhận được mã? <span class="verify-countdown">({{ time }}s)</span></p>
        <p class="re-send" v-if="time===0" @click="resendOpt">Gửi lại mã</p>
      </div>
      <div class="text-right">
        <button class="btn-send" @click="verifyOtp">Hoàn thành</button>
      </div>
    </div>
  </b-modal>
</template>
<script type="text/javascript">
import { verifyOtp, resendVerify } from '~/common/api/verify'

export default {
  data () {
    return {
      otpCode: '',
      textOtpError: '',
      interval: null,
      time: 90
    }
  },
  watch: {
    time (val) {
      if (val === 0) {
        clearInterval(this.interval)
      }
    }
  },
  methods: {
    showFormEnterOtp () {
      this.time = 90
      this.interval = setInterval(() => {
        this.time--
      }, 1000)
    },
    hideFormEnterOtp () {
      this.otpCode = ''
      if (this.interval) {
        clearInterval(this.interval)
      }
      this.time = 90
    },
    resendOpt () {
      resendVerify()
        .then((response) => {
          if (response.status === 200) {
            this.otpCode = ''
            this.showFormEnterOtp()
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
        })
    },
    verifyOtp () {
      if (!this.otpCode) {
        this.textOtpError = 'Vui lòng nhập mã xác thực.'
        this.$refs['input-otp'].$el.classList.add('is-invalid')
        return false
      }
      const data = {
        otp: this.otpCode
      }
      verifyOtp(data)
        .then((response) => {
          if (response.status === 200) {
            this.$bvModal.hide('modal-verify-otp')
            this.$emit('verify-opt-success')
            this.$store.dispatch('setUserMobile', response.data.mobile)
            return this.$notify({
              message: 'Xác thực số điện thoại thành công.',
              type: 'success'
            })
          }
        })
        .catch((err) => {
          return this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  #modal-verify-otp {
    input {
      height: 40px;
    }
    h4 {
      font-size: 22px;
      font-weight: bold;
    }
    .mb-text {
      font-size: 16px;
    }
    .mb-input {
      input {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #e1e7f5 !important;
        border-left: none;
      }
      .input-group-text {
        padding: 0.375rem 0.95rem;
        background: var(--primary);
        border: none;
        color: var(--white);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    .mb-desc {
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 0;
    }
    header{
      button {
        font-size: 16px;
      }
    }
  }
  .group-form {
    margin-bottom: 10px;
  }
  .btn-send {
    margin-top: 10px;
    margin-bottom: 10px;
    background: var(--primary);
    border-color: var(--primary);
    padding: 10px 18px;
    border-radius: 26px;
    color: var(--white);
  }
  .verify-text {
    font-size: 14px;
    line-height: 1.71;
    color: #92a2bf;
  }
  .verify-label {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .verify-code {
    border-radius: 8px;
    border: solid 1px #dce9f3 !important;
    margin-bottom: 5px;
  }
  .verify-bottom {
    .verify-desc {
      font-size: 16px;
      margin-bottom: 0;
      .verify-countdown {
        color: var(--primary);
      }
    }
    .re-send {
      font-size: 16px;
      color: var(--primary);
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 0;
      margin-left: auto;
    }
  }
  .fake-input {
    display: flex;
    .code {
      background: var(--primary);
      border: none;
      color: var(--white);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-size: 1rem;
      height: 40px;
      width: 50px;
      text-align: center;
      line-height: 40px;
    }
    .input {
      height: 40px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid #e1e7f5!important;
      border-left: none;
      width: 100%;
      padding: 0 .75rem;
      line-height: 40px;
    }
  }
  .mb-info {
    margin-top: 5px;
    font-size: 12px;
  }
</style>
