<template>
  <div class="item library-item-topic form-data" v-if="item">
    <div class="thumnail">
      <div class="ratio-custom custom-thumnail" style="--aspect-ratio:57.8%;">
        <div class="backdrop" :class="{'pointer': item.state !== 2}" @click="redirectView">
          <img v-if="item.state === 2" src="~/mlearn/icons/video/no-video.svg" alt="thumbnail" :title="item.name" class="thumbnail w-100 h-100" />
          <img v-else :src="item.thumbnail" alt="thumbnail" :title="item.name" class="thumbnail w-100 h-100" />
          <img src="~/mlearn/icons/video/play.svg" alt="image" class="icon position-absolute" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title-video display-html" :class="{'viewer': item.is_viewed, 'pointer': item.state !== 2}" :title="item.name" @click="redirectView">{{item.name}}</div>
    </div>
    <div class="action">
      <div class="item-action d-flex align-items-center">
        <img v-if="!item.is_free" alt="action" class="unlock pointer" src="~/mlearn/icons/video/lock.svg" title="Video chứa nội dung trả phí" @click="redirectView"/>
        <div v-if="item.state !== 2" class="duration d-inline-block pt-2">
          <template v-if="item.duration">
            {{item.duration | duration}}
          </template>
          <template v-else>
            00:00
          </template>
        </div>
        <div v-else class="duration not-ready d-inline-block">{{item.release_date ? ('Cập nhật ' + $helper.convertTime(item.release_date, '{d}/{m}/{y}')) : 'Đang cập nhật'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    redirectView () {
      if (!this.user) {
        this.$bvModal.show('modal-login')
        return
      }
      if (this.item.state === 2) {
        return
      }
      this.$helper.checkUserBuySub(this, this.item.is_free, '/khoa-hoc/' + this.item.slug)
    }
  }
}
</script>
<style lang="scss" scoped>
  .library-item-topic{
    .thumnail{
      width: 121px;
      margin-right: 12px;
      @media (max-width:1366px) {
        width: 100px;
        margin-right: 10px;
      }
      .backdrop{
        background-color: rgba(0,0,0,1);
        border-radius: 8px;
        img{
          &.thumbnail{
            opacity: 0.7;
            border-radius: 8px;
          }
          &.icon{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height:20px;
          }
        }
      }
    }
    .content{
      .title-video{
        line-height: 1.5;
        font-size: 16px;
        margin-right: 10px;
        -webkit-line-clamp: 3;
        @media (max-width:576px) {
          font-size: 14px;
          margin-right: 8px;
          -webkit-line-clamp: 2;
        }
        &.viewer{
          color: #75777B;
        }
      }
    }
    .action{
      .item-action{
        justify-content: center;
        .unlock{
          margin-right: 7px;
          @media (max-width:576px) {
            width: 15px;
            height:15px;
          }
        }
        .duration{
          font-size: 12px;
          color: #65676B;
          &.not-ready{
            color: #828282;
          }
        }
      }
    }
  }
</style>
